body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: black; /* Set background color of the body */
}

.App {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: black;
  color: rgb(255, 255, 255);
}

p {
  margin-left: 5%;
  margin-right: 5%;
}

label {
  font-size: 19px;
}

input {
  font-size: 19px;
}

select {
  font-size: 19px;
}

button {
  font-size: 19px;
  background-color: #00DEFF;
  padding: 5px 10px;
}

.inline-container {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
}

.component {
  margin-left: 15%;
}

.component2 {
  margin-left: 26.5%;
}

watermark {
  font-family: Arial, sans-serif;
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  margin-left: 0;
}

/* display: flex;
  margin-top: 0px;
  padding-top: 0px;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%; */

.navigation-container{
  position: fixed;
  top: 0;
  left: 0px;
}

.navigation-buttons{
  position: fixed;
  top: 0px;
  right: 10px;
}

.img{
  margin-left: 1000px;
}

h1{
  font-size: 60px;
  color: #00DEFF;
}

.watermark{
  position: fixed;
  left: 10px;
  top: 10px;
}

.pagebutton {
  margin-left:0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-top: 0px;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border: 5px solid #00DEFF;
  border-radius: 5px;
  cursor: pointer;
  background-color: black
}

.clickedpagebutton {
  margin-left:0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-top: 0px;
  /* background-color: #00DEFF; */
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border: 5px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
  background-color: black
}

.circularbutton {
  width: 30px;
  height: 30px;
  font-size: 25px;
  padding: 0px 0px;
  border-radius: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.canvasobject {
  overflow-x: "scroll";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
